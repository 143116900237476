import React, {Fragment} from "react";
import {Row, Col} from "react-bootstrap";
import {useAsyncSetState} from "use-async-setstate";

import ChangeDetails from "../../components/portal/change-details";
import ChangePassword from "../../components/portal/change-password";

const UserDetails = (props) => {
  const {user} = props;
  const [showChangeDetails, setShowChangeDetails] = useAsyncSetState(false);
  const [showChangePassword, setShowChangePassword] = useAsyncSetState(false);
  const {addresses} = user;

  const userAddress = addresses?.edges[0]?.node;

  return (
    <Fragment>
      {showChangeDetails && <ChangeDetails user={user} show onClose={() => setShowChangeDetails(false)} />}
      {showChangePassword && <ChangePassword user={user} show onClose={() => setShowChangePassword(false)} />}
      <Col xs={12} xl={4} className="mb-3">
        <div className="panel-container h-100">
          <div className="panel-header">User Details</div>
          <div className="panel-body h-100">
            <div className="user-details">
              <div className="user-label">Address</div>
              <div className="user-info">
                {`
                  ${userAddress?.unitNo}${userAddress?.unitNo && userAddress?.streetNo ? " / " : ""}
                  ${userAddress?.streetNo} ${userAddress?.address} ${userAddress?.suburb} 
                  ${userAddress?.state || ""} ${userAddress?.postCode || ""}${userAddress?.country ? "," : ""}
                  ${userAddress?.country?.name}
                `}
              </div>
              <div className="user-label">Name</div>
              <div className="user-info">{`${user.firstName} ${user.lastName}`}</div>

              <div className="user-label">Mobile</div>
              <div className="user-info">{`+${user.mobileNumber || ""}`}</div>

              <div className="user-label">Email</div>
              <div className="user-info">{`${user.email}`}</div>
            </div>
            <Row className="pt-3 row-border-top">
              <Col xs={12} sm={6}>
                <a
                  onClick={async () => setShowChangePassword(true)}
                  className="vw-button white-blue">
                  {"Change Password"}
                </a>
              </Col>
              <Col xs={12} sm={6}>
                <a
                  onClick={async() => setShowChangeDetails(true)}
                  className="vw-button white-blue">
                  {"Change Details"}
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </Col>
    </Fragment>
  );
};

export default UserDetails;
